// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    font-family: 'ALS Sector', sans-serif;
    font-size: 16px;
    width: 100%;
    text-align: center;
    background: transparent;
    color: #C7C7C9;
    margin-bottom: 2vh;
}


.footer__link {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,cAAc;IACd,kBAAkB;AACtB;;;AAGA;IACI,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".footer {\n    font-family: 'ALS Sector', sans-serif;\n    font-size: 16px;\n    width: 100%;\n    text-align: center;\n    background: transparent;\n    color: #C7C7C9;\n    margin-bottom: 2vh;\n}\n\n\n.footer__link {\n    cursor: pointer;\n    text-align: center;\n    text-decoration: none;\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
