// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --black: #000000;
    --blue: #006cdc;
    --white: #ffffff;
    --d-grey: #1C1C1E;
    --l-grey: #C7C7C9;
}

.list-option {
    font-family: "Roboto", sans-serif;
    width: -moz-fit-content;
    width: fit-content;
    padding: 13px 18px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    background-color: var(--d-grey);
}

.audiences-list {
    margin-top: 32px;
    overflow: auto;
    flex-grow: 1;
    scrollbar-width: none;
}

.audiences-line {
    /* padding: 0 16px; */
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;

    flex-wrap: nowrap;
    overflow-x: auto;
    /* position: absolute; */
    /* left: 0; */
}

.audiences-line {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.audiences-line::-webkit-scrollbar {
    display: none;
}

.floor-audiences {
    margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/audiencesList/audiencesList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iCAAiC;IACjC,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,WAAW;;IAEX,iBAAiB;IACjB,gBAAgB;IAChB,wBAAwB;IACxB,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[":root {\n    --black: #000000;\n    --blue: #006cdc;\n    --white: #ffffff;\n    --d-grey: #1C1C1E;\n    --l-grey: #C7C7C9;\n}\n\n.list-option {\n    font-family: \"Roboto\", sans-serif;\n    width: fit-content;\n    padding: 13px 18px;\n    border-radius: 100px;\n    display: flex;\n    align-items: center;\n    background-color: var(--d-grey);\n}\n\n.audiences-list {\n    margin-top: 32px;\n    overflow: auto;\n    flex-grow: 1;\n    scrollbar-width: none;\n}\n\n.audiences-line {\n    /* padding: 0 16px; */\n    margin-top: 6px;\n    display: flex;\n    flex-direction: row;\n    gap: 5px;\n    width: 100%;\n\n    flex-wrap: nowrap;\n    overflow-x: auto;\n    /* position: absolute; */\n    /* left: 0; */\n}\n\n.audiences-line {\n    scrollbar-width: none;\n    -ms-overflow-style: none;\n}\n\n.audiences-line::-webkit-scrollbar {\n    display: none;\n}\n\n.floor-audiences {\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
