// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myButton {
    border: 1px solid #fff;
    width: 100%;
    margin-bottom: 2vh;
    border-radius: 11px;
    height: 55px;
    color: #fff;
    background: transparent;
    font-weight: 400;
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,iCAAiC;IACjC,kBAAkB;AACtB","sourcesContent":[".myButton {\n    border: 1px solid #fff;\n    width: 100%;\n    margin-bottom: 2vh;\n    border-radius: 11px;\n    height: 55px;\n    color: #fff;\n    background: transparent;\n    font-weight: 400;\n    font-size: 17px;\n    font-family: \"Roboto\", sans-serif;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
